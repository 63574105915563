import React, {useEffect, useState} from "react"
import {Container,Button,Navbar,Nav} from 'react-bootstrap';
import { Link, navigate } from "gatsby"
import VideoImg1 from "../../assests/images/intro_img.jpg"; 
import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import Header from  "../../components/Common/Header"; 
import SEO from "../../components/seo"
import Custom_Intro from "../../components/modules/custom_intro"

import {config} from '../../components/Common/constant';

import { GET_ARTICLE } from "../../queries/common_use_query";
import { getUser } from "../../services/auth";

import TimeTrackerComp from "../../components/modules/time_tracker";

const PresentationPage = () => {
    var logged_user = getUser();
    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('custom-presentation-intro');

    return <>
    
    <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />

    <div className="pre-wrapper">
      
      <Header />
      
      <div className="section-wrapper custom_present_intro pt-0">
        <Container>
          <Custom_Intro logged_user={logged_user && logged_user.logged_user_data} />
        </Container>
      </div>
      
      <NavLink tab_name="Intro" prevLink="" nextLink={config.custom_launch} />

    </div>
    
    </>
}

export default PresentationPage